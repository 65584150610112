import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { subSeconds, addDays } from 'date-fns';
import { useHistory } from 'react-router-dom';

import useCountdown from 'utils/use-countdown';

import HoldScreen from 'ui/components/organisms/hold-screen';
import ErrorPanel from 'ui/components/atoms/error-panel';
import Upcoming from 'ui/components/layouts/upcoming';
import Video from 'ui/components/atoms/video-player';
import ClassCountdown from 'ui/components/atoms/class-countdown';

import useDismissEvent from 'app/hooks/use-dismiss-event';
import useAuxiliaryWindow from 'app/hooks/use-auxiliary-window';

import { UpcomingSyncScheduleQuery, LessonInstance } from 'app/in-studio/types/graphql';
import useConfig from 'app/in-studio/config-provider';

import { UpcomingSyncSchedule as UPCOMING_QUERY } from 'app/in-studio/graphql/queries/upcoming-lesson-instance.gql';

import { useAppState } from 'state';

type RedirectProps = {
  instance: Pick<LessonInstance, 'id' | 'workoutStartTime' | 'lobbyDurationSeconds'>,
  countdownSecs: number,
};

const Redirect = ({ instance, countdownSecs }: RedirectProps) => {
  const history = useHistory();

  const countdownStartTime = (instance.workoutStartTime && instance.lobbyDurationSeconds)
    ? subSeconds(new Date(instance.workoutStartTime), instance.lobbyDurationSeconds + countdownSecs)
    : new Date();

  useCountdown(countdownStartTime.toISOString(), {
    onComplete: () => {
      history.push(`/pre-lobby/${instance.id}`);
    },
  });

  return null;
};

type UpcomingDisplayProps = {
  workoutStartTime?: string,
  videoUrl?: string | null,
};

const UpcomingDisplay = ({ workoutStartTime, videoUrl }: UpcomingDisplayProps) => (
  <Upcoming
    video={(
      videoUrl ? <Video src={{ dashUrl: videoUrl }} playing loop /> : undefined
    )}
    countdown={(
      workoutStartTime ? <ClassCountdown workoutStartTime={workoutStartTime} text="Starts in" /> : undefined
    )}
  />
);

const UpcomingPage = () => {
  const holdingVideo = useAppState((state) => state.virtualStudio.holdingVideo);

  const [fromTime] = useState<string>(new Date().toISOString());
  const [toTime] = useState<string>((addDays(new Date(fromTime), 1)).toISOString());
  const [isAuxWindowConnected, setIsAuxWindowConnected] = useState(false);

  useDismissEvent();

  const { config } = useConfig();

  const { isAuxiliaryWindowConnected, sendWebkitMessage } = useAuxiliaryWindow();

  useEffect(() => {
    sendWebkitMessage();
    const checkConnected = async () => {
      const connected = await isAuxiliaryWindowConnected();
      setIsAuxWindowConnected(connected);
    };
    checkConnected();
  });

  const { loading, error, data } = useQuery<UpcomingSyncScheduleQuery>(UPCOMING_QUERY, {
    variables: {
      from: fromTime,
      to: toTime,
    },
    pollInterval: config.STUDIO_LESSON_POLL_INTERVAL_SECONDS * 1000,
  });

  const instanceList = data?.auth.user?.lessonInstanceSignUps?.edges;
  const firstInstance = instanceList && instanceList[0]?.node;

  // TODO pre-cache the video into an offline store + plug into video player
  // https://shaka-player-demo.appspot.com/docs/api/tutorial-offline.html
  // https://fiit-team.atlassian.net/browse/JS-3890

  if (isAuxWindowConnected) {
    return <HoldScreen />;
  }

  if (loading) {
    return <UpcomingDisplay />;
  }

  if (error) {
    return <ErrorPanel>{error.message}</ErrorPanel>;
  }

  if (!firstInstance || !firstInstance.workoutStartTime || !firstInstance.lobbyDurationSeconds) {
    return <UpcomingDisplay videoUrl={holdingVideo?.url} />;
  }

  const redirect = firstInstance.workoutStartTime && firstInstance.lobbyDurationSeconds;

  // TODO: Add event to workout stats to drive this, this is kind of magical right now.
  const countdownSecs = config.PRE_LOBBY_COUNTDOWN_SECONDS;

  return (
    <>
      <UpcomingDisplay
        workoutStartTime={firstInstance.workoutStartTime}
        videoUrl={holdingVideo?.url}
      />
      { redirect && (
        <Redirect
          instance={firstInstance}
          countdownSecs={countdownSecs}
        />
      )}
    </>
  );
};

export default UpcomingPage;
