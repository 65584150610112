export const getFutureDate = (daysToAdd: number) => {
  const date = new Date();
  const futureDate = date.getDate() + daysToAdd;
  date.setDate(futureDate);

  return date;
};

export const getStringFromDuration = (duration: number) => {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  return `${minutes ? `${minutes}min` : ''} ${seconds ? `${seconds}s` : ''}`;
};
