import React, { useMemo, useState } from 'react';
import BreakdownSection from 'ui/components/molecules/class-details-extra-information/breakdown-section';
import ExerciseDetailsRow from 'ui/components/molecules/class-details-extra-information/exercise-details-row';
import { MappedCircuit } from 'ui/components/molecules/current-exercise/utils';
import { getStringFromDuration } from 'utils/dates';

type ClassBreakdownProps = {
  mappedTimeline: MappedCircuit[],
};

const getBreakdownSections = (
  mappedTimeline: MappedCircuit[],
  expandedIndex: number,
  onClick: (index: number) => () => void,
) => (
  mappedTimeline.map(({ name, duration, type, activities }, index) => {
    const isBreakSection = ['INTRO_SECTION', 'TRANSITION_SECTION', 'OUTRO_SECTION'].includes(type || '');

    return (
      <ExerciseDetailsRow
        id={name}
        key={`${name}-${index}`}
        name={name}
        subText={getStringFromDuration(duration)}
        isBreakSection={isBreakSection}
        activities={activities}
        isExpanded={expandedIndex === index}
        onFocus={() => null}
        onClick={isBreakSection ? undefined : onClick(index)}
      />
    );
  })
);

const ClassBreakdown = ({ mappedTimeline }: ClassBreakdownProps) => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const sectionRows = useMemo(() => {
    const onClick = (index: number) => () => {
      setExpandedIndex((expandedIndex === index) ? -1 : index);
    };

    return getBreakdownSections(mappedTimeline, expandedIndex, onClick);
  }, [mappedTimeline, expandedIndex]);

  return (
    <BreakdownSection leftColumn={sectionRows} />
  );
};

export default ClassBreakdown;
