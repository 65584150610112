import { useAppState } from 'state';
import { RoutePermissions, verifyRoutePermissions } from 'app/in-studio/utils/route-permissions';
import useVirtualStudio from 'app/in-studio/hooks/use-virtual-studio';

type AllRouteConditions = {
  loggedIn: boolean,
  platformChecked: boolean,
  studioSet: boolean,
  permissionsFulfilled: boolean,
};

const useAllRouteConditions = (requiredPermissions: RoutePermissions[]): AllRouteConditions => {
  const loggedIn = useAppState(({ auth }) => auth.loggedIn);
  const virtualStudio = useVirtualStudio();
  const { permissions, isChecked: platformChecked, isSet: studioSet } = virtualStudio;
  const permissionsFulfilled = verifyRoutePermissions(permissions, requiredPermissions);

  return {
    loggedIn,
    platformChecked,
    studioSet,
    permissionsFulfilled,
  };
};

export default useAllRouteConditions;
