/* eslint-disable react/jsx-props-no-spreading, no-restricted-imports */
import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { subSeconds } from 'date-fns';

import useCountdown from 'utils/use-countdown';

import useLogger from 'app/hooks/use-logger';
import Idle from 'app/pages/idle-or-error';

import { LessonInstanceByIdQuery } from 'app/in-studio/types/graphql';

import {
  LessonInstanceById as LESSON_INSTANCE_QUERY,
} from 'app/in-studio/pages/lesson-instance/lesson-instance-by-id.gql';

import ClassCountdown from 'ui/components/atoms/class-countdown';
import ClassLength from 'ui/components/atoms/class-length';
import Button from 'ui/components/atoms/button';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';
import Typography from 'ui/components/atoms/typography';
import { trainerNames } from 'ui/components/utils/trainer-names';
import DotDivider from 'ui/components/atoms/dot-divider';
import EquipmentBreakdown from 'ui/components/atoms/equipment-breakdown';
import { rem } from 'ui/helpers';

import { getEqWithIcons } from 'app/in-studio/utils/eq-studio-mapper';

const Wrapper = styled.div<{ image?: string }>`
  background-image: url(${({ image }) => `${image}?size=800x800&mask=studio-lobby-background-gradient-square`});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  margin: ${rem(210)} 0 0 ${rem(160)};
`;

const Title = styled(Typography)`
  margin-bottom: ${rem(16)};
`;

const Trainers = styled(Typography)`
  margin-bottom: ${rem(16)};
`;

const CountdownContainer = styled.div`
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 1;
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${rem(16)};
`;

const IndentedText = styled.div`
  border-left: ${rem(6)} solid ${({ theme }) => theme.colors.beckersKnop};
  display: flex;
  flex-direction: column;
  width: ${rem(700)};
  padding-left: ${rem(16)};
  margin-top: ${rem(80)};
`;

const BackButton = styled(Button)`
  position: absolute;
  left: ${rem(50)};
  bottom: ${rem(50)};
  z-index: 1;
`;

const DescHeader = styled(Typography)`
  font-weight: 700;
`;

const Difficulty = styled(Typography)`
  padding: 0 0 0 ${rem(10)};
`;

const EquipmentWrapper = styled.div`
  margin: ${rem(16)} 0;
`;

type ComponentProps = { id: string };

type Props = RouteComponentProps<ComponentProps>;

type CountdownProps = {
  lessonInstance: LessonInstanceByIdQuery['lessonInstanceById'],
};

const PreLobby = ({ lessonInstance }: CountdownProps) => {
  const history = useHistory();

  const lobbyOpenTime = (lessonInstance.workoutStartTime && lessonInstance.lobbyDurationSeconds)
    ? subSeconds(new Date(lessonInstance.workoutStartTime), lessonInstance.lobbyDurationSeconds)
    : new Date();

  useCountdown(lobbyOpenTime.toISOString(), {
    onComplete: () => {
      history.push(`/lesson-instance/${lessonInstance.id}`);
    },
  });

  const lobbyDurationMinutes = Math.ceil((lessonInstance.lobbyDurationSeconds || 0) / 60);

  if (!lessonInstance.workoutStartTime) {
    return <Idle />;
  }

  const eqIcons = getEqWithIcons({
    eqLevels: lessonInstance.lesson.equipmentLevel,
    studios: [lessonInstance.lesson.studio.id],
  });

  return (
    <Wrapper image={lessonInstance.lesson.mainImage?.url}>
      {/* eslint-disable jsx-a11y/media-has-caption */}
      <audio id="audio" loop autoPlay>
        <source src={lessonInstance.lobbyMusicUrl} type="audio/mpeg" />
      </audio>
      <TitleContainer>
        <Title variant="paragon" as="h1">{lessonInstance.lesson.name}</Title>
        <Trainers as="p" weight="bold">{trainerNames(lessonInstance.lesson.trainers, 'with')}</Trainers>
        <Details>
          <ClassLength showIcon duration={lessonInstance.lesson.durationRange} />
          <DotDivider />
          <Difficulty variant="pica">{lessonInstance.lesson.difficulty.replace('_', ' ')}</Difficulty>
        </Details>
        {Object.keys(eqIcons).length > 0 ? (
          <EquipmentWrapper>
            {Object.entries(eqIcons).map(([, { displayName, levels, icon }]) => (
              <EquipmentBreakdown
                levels={levels}
                title={displayName}
                icon={icon}
                key={`eq-${displayName}`}
              />
            ))}
          </EquipmentWrapper>
        ) : null}
        <IndentedText>
          <DescHeader>Ready to join?</DescHeader>
          <Typography>Grab your equipment and let&apos;s get moving!</Typography>
          <Typography>
            Join the class by scanning the QR code {lobbyDurationMinutes} minutes before start time. Let&apos;s go!
          </Typography>
        </IndentedText>
      </TitleContainer>
      { lessonInstance.workoutStartTime && (
        <CountdownContainer>
          <ClassCountdown workoutStartTime={lessonInstance.workoutStartTime} text="Starts in" />
        </CountdownContainer>
      )}
      <BackButton
        onClick={() => history.goBack()}
        labelPosition="right"
        icon={<ChevronLeft />}
        iconOnly
      />
    </Wrapper>
  );
};

const PreLobbyLoader = ({ id }: ComponentProps) => {
  const logger = useLogger('in-studio:prelobby-page');

  const { loading, error, data } = useQuery<LessonInstanceByIdQuery>(LESSON_INSTANCE_QUERY, {
    variables: { id },
    onError: (e) => logger.error('LessonInstanceByIdQuery error', { error: e }),
  });

  if (loading) {
    return <Idle redirect={false} />;
  }

  if (error) {
    return <Idle />;
  }

  if (!data?.lessonInstanceById) {
    return <Idle />;
  }

  const { lessonInstanceById: lessonInstance } = data;

  return <PreLobby lessonInstance={lessonInstance} />;
};

const PreLobbyPage = ({ match: { params: { id } } }: Props) => (
  id ? <PreLobbyLoader id={id} /> : <p>No id screen/redirect</p>
);

export default PreLobbyPage;
