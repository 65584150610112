export type VirtualStudioPermissions = {
  exerciseLibrary?: boolean | null,
  classBuilder?: boolean | null,
  liveStudioClass?: boolean | null,
  scheduler?: boolean | null,
};

export enum VirtualStudioActionTypes {
  SET_VIRTUAL_STUDIO = 'SET_VIRTUAL_STUDIO',
}

export const setVirtualStudio = (payload: {
  id: string,
  name: string,
  permissions: VirtualStudioPermissions,
}) => ({
  type: VirtualStudioActionTypes.SET_VIRTUAL_STUDIO,
  payload,
});
export type SetVirtualStudio = typeof setVirtualStudio;

export default { setVirtualStudio };
