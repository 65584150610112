import React, { ReactElement } from 'react';
import HeroCard from 'ui/components/molecules/hero-card';
import HeroCarousel from 'ui/components/molecules/hero-carousel';

export type HeroCardContent = Array<{
  title: string,
  description: ReactElement,
  to?: string,
  backgroundImage: {
    url: string,
  }
}>;

type Props = {
  onFocus: (el: HTMLElement | null) => void,
  autofocus: boolean,
  heroCardContent: HeroCardContent,
  className?: string,
  peek?: boolean,
  disableFocus?: boolean,
};

const BrowseCarousel = ({ onFocus, heroCardContent, autofocus, className, peek, disableFocus } : Props) => (
  <HeroCarousel onFocus={onFocus} className={className} peek={peek}>
    {heroCardContent.map((content, i) => (
      <HeroCard key={i} {...content} autofocus={autofocus && i === 0} disableFocus={disableFocus} />
    ))}
  </HeroCarousel>
);

export default BrowseCarousel;
