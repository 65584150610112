import React, { useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Typography from 'ui/components/atoms/typography';
import RailCard from 'ui/components/atoms/rail-card';
import Padlock from 'ui/components/atoms/icons/padlock';
import { rem } from 'ui/helpers';
import { useSpatialTarget } from 'utils/spatial-nav';
import TestingBadge from 'ui/components/atoms/testing-badge';
import { pluralise } from 'ui/components/utils/pluralise';

export type Props = {
  name: string,
  blocks: number,
  isPartner: boolean,
  logoImageUrl?: string,
  backgroundImage?: string,
  to?: string,
  focused?: boolean,
  locked?: boolean,
  dataTest?: string,
  autofocus?: boolean,
  isWithinGrid?: boolean,
  focusedName?: string,
  inTesting?: boolean,
};

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: end;
  align-items: baseline;
  border-radius: ${rem(8)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: ${({ theme }) => theme.spacing.s};
`;

const ContentPackageName = styled(Typography) <{ locked: Boolean }>`
  opacity: ${({ locked }) => (locked ? '0.55' : '1')};
  white-space: pre-line;
`;

const ContentPackageBlocks = styled(Typography) <{ locked: Boolean }>`
  opacity: ${({ locked }) => (locked ? '0.55' : '0.8')};
  text-transform: uppercase;
`;

const PadlockContainer = styled.div`
  border: 1px solid white;
  border-radius: 1000px;
  padding: ${() => rem(20)};
  position: absolute;
  left: 50%;
  top: 50%;
  line-height: 0;
  transform: translate(-50%, -50%);
`;

const CardLink = styled(Link)`
  display: block;
`;

const StyledTestingBadge = styled(TestingBadge)`
  top: 0.5rem;
  right: 0.5rem;
`;

const LogoImage = styled.div<{ image: string }>`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 1.5rem;
  top: 0.5rem;
  right: 0.5rem;
  background-image: url(${({ image }) => image});
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
`;

const getBlocksText = (blocks: number, isPartner: boolean) => {
  const name = isPartner ? 'block' : 'collection';
  return pluralise(blocks, name, `${name}s`);
};

const getImageQueryParams = (locked?: boolean) => {
  const sizeParam = '852x480';
  const mask = locked ? 'class-card-locked-out-of-focus-gradient-v1' : 'class-card-out-of-focus-gradient-v1';
  return `size=${sizeParam}&mask=${mask}`;
};

const ContentPackageCard = ({
  name,
  focusedName = name,
  backgroundImage,
  to,
  locked = false,
  dataTest,
  autofocus = false,
  isWithinGrid = false,
  inTesting,
  blocks,
  isPartner,
  logoImageUrl,
}: Props) => {
  const elRef = useRef(null);
  const { focused } = useSpatialTarget({
    elRef,
    autofocus,
  });

  const card = (
    <RailCard size="large" focus={focused} boxSize="large" isWithinGrid={isWithinGrid} box>
      {inTesting && <StyledTestingBadge />}
      {logoImageUrl ? <LogoImage image={logoImageUrl} /> : null}
      <CardContent style={{ backgroundImage: `url(${backgroundImage}?${getImageQueryParams(locked)}` }}>
        {locked && <PadlockContainer><Padlock /></PadlockContainer>}
        <ContentPackageName
          locked={locked}
          color="white"
          variant="body-copy"
          data-test={dataTest}
          weight="bold"
        >
          {focused ? focusedName : name}
        </ContentPackageName>
        <ContentPackageBlocks
          locked={locked}
          color="white"
          variant="long-primer"
          weight="regular"
        >
          {blocks} {getBlocksText(blocks, isPartner)}
        </ContentPackageBlocks>
      </CardContent>
    </RailCard>
  );

  // TODO after training plan details cards are clickable remove this hack
  if (!to) {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <div tabIndex={0} ref={elRef}>
        {card}
      </div>
    );
  }

  return (
    <CardLink ref={elRef} to={to} data-test="card-link">
      { card }
    </CardLink>
  );
};

export default ContentPackageCard;
