import React, { ReactElement, useRef } from 'react';
import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { Image } from 'types/graphql';
import { rem } from 'ui/helpers';
import { useSpatialTarget } from 'utils/spatial-nav';
import { Link } from 'react-router-dom';
import CardFocus from 'ui/components/atoms/card-focus';

export type Props = {
  backgroundImage: Pick<Image, 'url'>,
  className?: string,
  title: string,
  description: ReactElement,
  autofocus?: boolean,
  to?: string,
  disableFocus?: boolean,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: baseline;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  border-radius: ${rem(8)};
  padding: ${({ theme }) => theme.spacing.xl};
  position: relative;
  height: ${rem(380)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 42%;
  white-space: normal;
`;

const CardLink = styled(Link)`
  width: 100%;
  display: flex;
`;

const StyledCardFocus = styled(CardFocus)<{ focus: boolean, disableFocus: boolean }>`
  ${({ focus, disableFocus, theme }) => {
    if (disableFocus) {
      return '';
    }
    const borderColor = focus ? theme.colors.black : theme.colors.fordDarkCharcoal;
    return `border: 1px solid ${borderColor}`;
  }}
`;

const HeroCard = ({
  backgroundImage,
  className,
  title,
  description,
  autofocus = false,
  to,
  disableFocus = false,
}: Props) => {
  const elRef = useRef(null);
  const { focused } = useSpatialTarget({
    elRef,
    autofocus,
    disabled: !to,
  });

  const Card = (
    <StyledCardFocus box focus={focused} boxSize="large" data-test="hero-card" disableFocus={disableFocus}>
      <Wrapper
        className={className}
        ref={elRef}
        style={{ backgroundImage: `url(${backgroundImage.url})` }}
      >
        <Content>
          <Typography variant="paragon" color="white">
            {title}
          </Typography>
          <Typography variant="body-copy">
            {description}
          </Typography>
        </Content>
      </Wrapper>
    </StyledCardFocus>
  );

  return to ? (
    <CardLink ref={elRef} to={to} className={className}>
      {Card}
    </CardLink>
  ) : Card;
};

export default HeroCard;
