import { StudioType } from 'app/in-studio/types/graphql';

import AirbikeIcon from 'ui/components/atoms/icons/airbike';
import DumbbellIcon from 'ui/components/atoms/icons/dumbbell';
import KettlebellIcon from 'ui/components/atoms/icons/kettlebell';
import RowIcon from 'ui/components/atoms/icons/row';
import SpinIcon from 'ui/components/atoms/icons/spin';
import TreadIcon from 'ui/components/atoms/icons/tread';

type EquipmentBreakdownDetails = {
  displayName: string,
  levels: string[],
  icon: any,
};

type EquipmentLevel = {
  id: string,
  displayName: string,
  equipment: {
    value: string,
    shortDisplay: string,
  },
};

type GetEqWithIconsArgs = {
  eqLevels: EquipmentLevel[],
  studios: StudioType[],
};

const getIconForEq = (eqName: string) => {
  switch (eqName.toLowerCase()) {
    case 'airbike':
      return AirbikeIcon;
    case 'dumbbells':
      return DumbbellIcon;
    case 'kettlebell':
      return KettlebellIcon;
    case 'row':
      return RowIcon;
    case 'spin':
      return SpinIcon;
    case 'tread':
      return TreadIcon;
    default:
      return undefined;
  }
};

const eqByStudio = (studioIds: StudioType[]): Record<string, EquipmentBreakdownDetails> => (
  studioIds.reduce((acc: Record<string, EquipmentBreakdownDetails>, studioId) => {
    acc[studioId] = {
      displayName: studioId.charAt(0).toUpperCase() + studioId.slice(1).toLowerCase(),
      levels: [],
      icon: getIconForEq(studioId.toLowerCase()),
    };
    return acc;
  }, {})
);

export const getEqWithIcons = ({ eqLevels = [], studios = [] }: GetEqWithIconsArgs) => (
  eqLevels.reduce((acc: Record<string, EquipmentBreakdownDetails>, obj) => {
    const key = obj.equipment?.value;
    if (!key || !obj.equipment?.shortDisplay || !obj.displayName) {
      return acc;
    }
    if (!acc[key]) {
      acc[key] = { displayName: obj.equipment.shortDisplay, levels: [], icon: getIconForEq(key) };
    }
    acc[key] = { ...acc[key], levels: [...acc[key].levels, obj.displayName] };
    return acc;
  }, eqByStudio(studios))
);
