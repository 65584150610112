import React from 'react';
import styled from 'styled-components';

import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';

type EquipmentBreakdownProps = {
  title: string,
  icon?: (props: any) => JSX.Element,
  levels: string[],
  className?: string,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(24)};
  margin: ${rem(16)} 0;
`;

const IconWrapper = styled.div`
  width: ${rem(80)};
  height: ${rem(80)};
  ${({ theme }) => `background-color: ${theme.colors.betaGrey}`};
  border-radius: ${rem(8)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled.div`
  min-width: ${rem(50)};
  min-height: ${rem(50)};
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(Typography)`
  font-weight: 700;
`;

const EquipmentBreakdown = ({ title, icon, levels, className }: EquipmentBreakdownProps) => {
  if (!icon) {
    return null;
  }
  return (
    <Wrapper className={className}>
      <IconWrapper>
        <StyledIcon as={icon} className={`${title}-icon`} />
      </IconWrapper>
      <Details>
        <Title>{title}</Title>
        {levels.length > 0 ? (<Typography>{levels.join(', ')}</Typography>) : null}
      </Details>
    </Wrapper>
  );
};

export default EquipmentBreakdown;
