import React from 'react';
import styled from 'styled-components';
import { rem } from 'ui/helpers';

const Wrapper = styled.ol`
  list-style: none;
  display: flex;
  padding: 0;
`;
const IconHolder = styled.li`
  margin-left: ${rem(40)};

  &:first-of-type {
    margin-left: 0;
  }
`;

const Dot = styled.div<{ selected: boolean}>`
  height: ${rem(10)};
  width: ${rem(10)};
  border-radius: ${rem(5)};
  background-color: ${({ theme }) => theme.colors.white};
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
`;

export type Props = {
  count: number,
  selectedIndex: number,
};

const CarouselIndicators = ({ count = 0, selectedIndex = 0 }: Props) => (
  <Wrapper>
    {
      Array(count).fill('').map((_, i) => (
        // No key so resorting to index
        // eslint-disable-next-line react/no-array-index-key
        <IconHolder key={i}>
          <Dot selected={selectedIndex === i} />
        </IconHolder>
      ))
    }
  </Wrapper>
);

export default CarouselIndicators;
