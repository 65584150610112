import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

import { useAppState } from 'state';

import {
  Library as LIBRARY,
} from 'app/in-studio/pages/library/library.gql';

import { contentPackageToRailNodes } from 'app/in-studio/pages/landing/helpers';

import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import Rail from 'ui/components/molecules/rail';

import useRoutes from 'utils/use-routes';

import Typography from 'ui/components/atoms/typography';

const Wrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.xl};
  display: flex;
  flex-direction: column;
`;

const StyledRail = styled(Rail)`
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const Header = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

const Description = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
`;

const LibraryPageDetails = ({ userId }: any) => {
  const { data, loading, error } = useQuery(LIBRARY, {
    variables: {
      userId,
    },
  });
  const { routes } = useRoutes();

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const fiitContentPackagesRail = data.fiitContentPackages.edges
    ?.map(contentPackageToRailNodes(routes.CONTENT_PACKAGE)) || [];
  const partnerContentPackagesRail = data.partnerContentPackages.edges
    ?.map(contentPackageToRailNodes(routes.CONTENT_PACKAGE)) || [];

  return (
    <Wrapper>
      <Header variant="double-pica">Library</Header>
      <Description variant="body-copy">
        {/* eslint-disable-next-line max-len */}
        Transform your gym experience with a variety of world-class workouts from FIIT and our partners like Hyrox and Ramfit.
      </Description>
      {(fiitContentPackagesRail && fiitContentPackagesRail.length > 0) && (
        <StyledRail
          title="FIIT workouts"
          key="fiitContentPackages"
        >
          { fiitContentPackagesRail }
        </StyledRail>
      )}
      {(partnerContentPackagesRail && partnerContentPackagesRail.length > 0) && (
        <StyledRail
          title="Partner workouts"
          key="partnerContentPackages"
        >
          { partnerContentPackagesRail }
        </StyledRail>
      )}
    </Wrapper>
  );
};

const LibraryPage = () => {
  const userId = useAppState((state) => state.auth.userId);

  if (!userId) {
    return <div>error</div>;
  }

  return <LibraryPageDetails userId={userId} />;
};

LibraryPage.menu = true;

export default LibraryPage;
