import React, { useState, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import useRoutes, { url } from 'utils/use-routes';

import transformLessonData, { LessonInput } from 'app/in-studio/utils/transform-lesson-data';

import { useAppState } from 'state';

import {
  ContentPackageDetails as CONTENT_PACKAGE_DETAILS,
} from 'src/app/in-studio/pages/content-package/content-package-details.gql';
import {
  ContentPackageDetailsQuery,
  ContentPackageDetailsQueryVariables,
  LessonStatus,
  StudioType,
} from 'app/in-studio/types/graphql';

import ErrorOverlay from 'ui/components/molecules/loading-error-screen';
import LoadingScreen from 'ui/components/molecules/loading-screen';
import ClassCard from 'ui/components/molecules/class-card';
import LessonList from 'ui/components/molecules/lesson-list';
import Typography from 'ui/components/atoms/typography';
import Button from 'ui/components/atoms/button';
import PadlockIcon from 'ui/components/atoms/icons/padlock';
import EquipmentBreakdown from 'ui/components/atoms/equipment-breakdown';
import { getEqWithIcons } from 'app/in-studio/utils/eq-studio-mapper';
import useDismissEvent from 'app/hooks/use-dismiss-event';

type ComponentProps = {
  id: string,
};

export type Props = RouteComponentProps<ComponentProps>;

type ContentPackageEquipmentLevel = {
  id: string,
  displayName: string,
  equipment: {
    id: string,
    value: string,
    shortDisplay: string,
  },
};

type ContentPackageBlockStudio = {
  id: StudioType,
};

type ContentPackageBlockNode = {
  node: {
    id: string,
    name: string,
    description?: string | null,
    coverImage: {
      id: number,
      url: string,
    } | null,
    lessons: {
      edges: LessonInput[] | null,
    },
    studios: ContentPackageBlockStudio[],
    equipmentLevels: Array<ContentPackageEquipmentLevel | undefined>,
    permissionsWithReasons: {
      studioAccess: {
        value: boolean,
      },
    },
  } | null,
};

type ContentPackageDetailsProps = {
  name: string,
  blocks: ContentPackageBlockNode[],
};

type ContentPackageWrapperProps = {
  id: string,
  userId: number,
};

const Wrapper = styled.div<{ image?: string }>`
  margin-left: ${({ theme }) => theme.spacing.xl};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xl};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-image: url(${({ image }) => image});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
    z-index: -1;
  }
`;

const PillsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.s};
`;

const BlockNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.s};
`;

const OverviewWrapper = styled.div`
  width: 27.3rem;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.m};
`;

const StyledButton = styled(Button)`
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.m}`};
`;

const ContactPSMButton = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.textButtonBackground};
  color: ${({ theme }) => theme.colors.textButtonText};
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
  border-radius: ${({ theme }) => theme.spacing.l};
`;

const EquipmentGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${({ theme }) => `0 ${theme.spacing.m}`};
`;

const StyledEquipmentBreakdown = styled(EquipmentBreakdown)`
  width: 8.5rem;
`;

const getEqIcons = (selectedBlock: ContentPackageBlockNode) => {
  const eqLevels = selectedBlock?.node?.equipmentLevels.filter(
    (eqLevel): eqLevel is ContentPackageEquipmentLevel => !!eqLevel
  ) || [];

  const studios = selectedBlock?.node?.studios.map(({ id }) => id).filter((studio) => !!studio) || [];

  const unorderedEqWithIcons = getEqWithIcons({ eqLevels, studios });

  return Object.entries(unorderedEqWithIcons).sort(([, a], [, b]) => (a.displayName > b.displayName ? 1 : -1));
};

const ContentPackageDetails = ({ name, blocks }: ContentPackageDetailsProps) => {
  const { routes } = useRoutes();
  const [selectedBlockIndex, setSelectedBlockIndex] = useState(0);

  const selectedBlock = blocks[selectedBlockIndex];

  const lessons = useMemo(() => transformLessonData(selectedBlock?.node?.lessons?.edges || []), [selectedBlock]);

  useDismissEvent();

  if (!selectedBlock) {
    return <ErrorOverlay onDismiss="back" />;
  }

  const eqIcons = getEqIcons(selectedBlock);

  return (
    <Wrapper image={selectedBlock?.node?.coverImage?.url}>
      <PillsWrapper>
        {blocks.map((block, index) => (
          <StyledButton
            key={`button_${index}`}
            variant="pill"
            className={`button_${index}`}
            selected={index === selectedBlockIndex}
            label={block.node?.name}
            onClick={() => setSelectedBlockIndex(index)}
            icon={block?.node?.permissionsWithReasons.studioAccess.value ? null : <PadlockIcon />}
          />
        ))}
      </PillsWrapper>
      <OverviewWrapper>
        <Typography variant="paragon">{name}</Typography>
        <BlockNameWrapper>
          <Typography variant="double-pica">{selectedBlock.node?.name}</Typography>
          {!selectedBlock?.node?.permissionsWithReasons.studioAccess.value && <PadlockIcon />}
        </BlockNameWrapper>
        <Typography variant="body-copy">{selectedBlock.node?.description}</Typography>
        <EquipmentGrid>
          {
            eqIcons.map(([, { icon, displayName }]) => (
              <StyledEquipmentBreakdown
                key={`eq-${displayName}`}
                className={`eq-${displayName}`}
                levels={[]}
                icon={icon}
                title={displayName}
              />
            ))
          }
        </EquipmentGrid>
        {!selectedBlock?.node?.permissionsWithReasons.studioAccess.value && (
          <ContactPSMButton>Contact your PSM to find out more</ContactPSMButton>
        )}
      </OverviewWrapper>
      <LessonList itemSpacing="m" variant="GRID" columns={4}>
        {
          lessons.map((lesson) => (
            <ClassCard
              key={lesson.id}
              backgroundImage={lesson.mainImageUrl}
              size="large"
              duration={lesson.duration}
              trainer={lesson.trainerName}
              name={lesson.name}
              to={url({ route: routes.LESSON_DETAILS, params: { id: lesson.id } })}
              locked={lesson.locked}
              completed={lesson.completed}
              kettlebells={lesson.kettlebells}
              dumbbells={lesson.dumbbells}
              favourited={lesson.favourited}
              isWithinGrid
              inTesting={lesson.status === LessonStatus.TESTING}
            />
          ))
        }
      </LessonList>

    </Wrapper>
  );
};

const ContentPackageWrapper = ({ userId, id }: ContentPackageWrapperProps) => {
  const {
    data,
    loading,
    error,
  } = useQuery<ContentPackageDetailsQuery, ContentPackageDetailsQueryVariables>(CONTENT_PACKAGE_DETAILS, {
    variables: {
      userId,
      id,
    },
  });

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data || !data.contentPackageById || !data.contentPackageById.contentPackageBlocks.edges?.length) {
    return <ErrorOverlay error={error} onDismiss="back" />;
  }

  const { name, contentPackageBlocks: { edges: blocks } } = data.contentPackageById;

  if (!blocks) {
    return <ErrorOverlay onDismiss="back" />;
  }

  return <ContentPackageDetails name={name} blocks={blocks} />;
};

const ContentPackagePage = ({ match: { params } }: Props) => {
  const contentPackageId = params.id;
  const userId = useAppState((state) => state.auth.userId);

  if (!contentPackageId || !userId) {
    return <ErrorOverlay onDismiss="back" />;
  }

  return <ContentPackageWrapper id={contentPackageId} userId={userId} />;
};

ContentPackagePage.menu = true;

export default ContentPackagePage;
