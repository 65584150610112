import React from 'react';
import styled from 'styled-components';
import { Set, TimelineActivity, TimelineCircuit } from 'types/graphql';
import ChevronRight from 'ui/components/atoms/icons/chevron-right';
import Typography from 'ui/components/atoms/typography';
import BreakdownSectionRow, {
  CommonProps as BreakdownSectionRowProps,
} from 'ui/components/molecules/class-details-extra-information/breakdown-section-row';
import { rem } from 'ui/helpers';
import { getStringFromDuration } from 'utils/dates';

type ExerciseDetailsRowProps = BreakdownSectionRowProps & {
  isBreakSection?: boolean,
  name?: string | null,
  subText?: string | null,
  activities?: TimelineActivity[],
  isExpanded: boolean,
};

const ExerciseDetailsContainer = styled(BreakdownSectionRow)<{ isBreakSection: boolean }>`
  align-items: center;
  flex-direction: row;
  padding: 0 2rem;
  min-height: ${({ isBreakSection }) => (isBreakSection ? rem(80) : rem(120))};
  background-color: ${({ theme, isBreakSection }) => (
    isBreakSection ? theme.colors.black : theme.colors.fordDarkCharcoal
  )};
`;

const TimelineActivityDetailsContainer = styled.button<{ isLastElement: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${rem(5)};
  align-items: center;
  flex-direction: row;
  padding: 0 2rem;
  min-height: ${rem(60)};
  margin-bottom: ${({ isLastElement }) => (isLastElement ? rem(45) : rem(5))};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 2rem;
    bottom: 0;
    right: 2rem;
    height: 1px;
    background: ${({ theme }) => theme.colors.white};
  }
`;

const TitleDurationContainer = styled(Typography)`
  display: flex;
  flex-direction: column;
  max-width: ${rem(700)};
`;

const Title = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
`;

const TransparentText = styled(Typography)`
  opacity: 0.5;
  text-align: left;
`;

const StyledChevronIcon = styled(ChevronRight)<{ isExpanded: boolean }>`
  transform: rotate(${({ isExpanded }) => (isExpanded ? '270deg' : '90deg')});
`;

const getActivitiesSections = (activities: TimelineActivity[]) => {
  const activityView = (displayName: string, duration: number, index: number, isLastElement: boolean) => (
    <TimelineActivityDetailsContainer
      id={`activity-${index}`}
      key={`activity-${index}`}
      isLastElement={isLastElement}
      onFocus={() => {}}
    >
      <Title><TransparentText>•</TransparentText> {displayName}</Title>
      <TransparentText size="s">{getStringFromDuration(duration)}</TransparentText>
    </TimelineActivityDetailsContainer>
  );

  const filterSets = (activitiesToFilter: TimelineActivity[]) => (
    activitiesToFilter.filter((a) => a.__typename === 'Set') as Set[]
  );

  const setActivities: Set[] = filterSets(activities);

  const timelineCircuitActivities = activities.filter((a) => a.__typename === 'TimelineCircuit') as TimelineCircuit[];
  if (timelineCircuitActivities.length) {
    setActivities.push(...timelineCircuitActivities.flatMap((a) => filterSets(a.activities)));
  }

  const total = setActivities.length - 1;
  return setActivities.map(({ displayName, duration }, i) => activityView(displayName, duration, i, i === total));
};

const ExerciseDetailsRow = ({
  id,
  name,
  subText,
  isBreakSection = false,
  activities,
  isExpanded,
  onFocus,
  onClick,
}: ExerciseDetailsRowProps) => (
  <>
    <ExerciseDetailsContainer isBreakSection={isBreakSection} id={id} onFocus={onFocus} onClick={onClick}>
      <TitleDurationContainer>
        <Title weight="bold">{name}</Title>
        <TransparentText size="xs">{subText}</TransparentText>
      </TitleDurationContainer>
      {activities && onClick && !isBreakSection ? <StyledChevronIcon isExpanded={isExpanded} /> : null}
    </ExerciseDetailsContainer>
    {isExpanded && activities ? getActivitiesSections(activities) : null}
  </>
);

export default ExerciseDetailsRow;
