import React, { useState, useCallback, ReactNode, useMemo, useRef } from 'react';
import styled from 'styled-components';
import CarouselIndicators from 'ui/components/atoms/carousel-indicators';
import { rem } from 'ui/helpers';
import { SpatialNavParent } from 'utils/spatial-nav';
import { useAppState } from 'state';

export type Props = {
  children: ReactNode[],
  onFocus: (el: HTMLElement | null) => void,
  className?: string,
  peek?: boolean,
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  margin-top: ${({ theme }) => theme.spacing.l};
  margin-right: 1rem;
`;

const CarouselWrapper = styled.div`
  margin-top: ${rem(40)};
  text-align: left;
  position: relative;
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
`;

const IndicatorHolder = styled.div<{ peek?: boolean }>`
  position: absolute;
  bottom: 0;
  left: ${({ peek }) => (peek ? '42.5%' : '50%')};
  transform: translate(-50%, -50%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: ${rem(20)};
`;

const Slide = styled.div<{ transitionEnabled: boolean, peek?: boolean }>`
  display: flex;
  min-width: ${({ peek }) => (peek ? '85%' : '100%')};
  margin-right: ${({ transitionEnabled }) => (transitionEnabled ? '1' : '10')}rem;
  scroll-snap-align: start;
`;

const HeroCarousel = ({ children, onFocus, className, peek }: Props) => {
  const carouselWrapperRef = useRef<HTMLDivElement>(null);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const dynamicHeroTransitionEnabled = useAppState((state) => state.flag.dynamicHeroTransitionEnabled);

  const onItemFocused = useCallback((element: HTMLElement | null, selectedIndex: number) => {
    if (element && carouselWrapperRef.current) {
      carouselWrapperRef.current.scrollTo({
        left: element.offsetLeft,
        behavior: dynamicHeroTransitionEnabled ? 'smooth' : 'auto',
      });
      setSelectedSlideIndex(selectedIndex);
    }
  }, [dynamicHeroTransitionEnabled]);

  const slides = useMemo(() => children.map((slide, i) => (
    <Slide transitionEnabled={dynamicHeroTransitionEnabled} key={i} peek={peek}>
      <SpatialNavParent layout="vertical" onFocus={(el) => onItemFocused(el, i)}>
        {slide}
      </SpatialNavParent>
    </Slide>
  )), [children, onItemFocused, dynamicHeroTransitionEnabled, peek]);

  return (
    <Wrapper data-test="hero-carousel" className={className}>
      <SpatialNavParent layout="horizontal" onFocus={onFocus}>
        <CarouselWrapper ref={carouselWrapperRef}>
          {slides}
        </CarouselWrapper>
        <IndicatorHolder peek={peek}>
          <CarouselIndicators count={children.length} selectedIndex={selectedSlideIndex} />
        </IndicatorHolder>
      </SpatialNavParent>
    </Wrapper>
  );
};

export default HeroCarousel;
