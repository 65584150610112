import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { rem } from 'ui/helpers';

import { IconProps } from 'ui/components/atoms/icons/types';

// Default height and width can be overwritten by usage
const Svg = styled.svg`
  width: ${rem(24)};
  height: ${rem(24)};
`;

function ClassesIcon({ className = '', color = 'white' }: IconProps) {
  const theme = useTheme();
  const fill = theme.colors[color];
  return (
    <Svg viewBox="0 0 24 24" className={className}>
      <path
        d="M19.2 13.5c-2.6 0-4.8 2.1-4.8 4.8 0 2.6 2.2 4.8 4.8 4.8 2.7 0 4.8-2.1 4.8-4.8 0-2.7-2.1-4.8-4.8-4.8m-14.4 0c-2.7 0-4.8 2.1-4.8 4.8C0 20.9 2.2 23 4.8 23c2.7 0 4.8-2.1 4.8-4.8 0-2.6-2.1-4.7-4.8-4.7m12-7.7c0 2.6-2.1 4.8-4.8 4.8-2.7 0-4.8-2.1-4.8-4.8S9.4 1 12 1c2.7 0 4.8 2.1 4.8 4.8"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
      />
    </Svg>
  );
}

export default ClassesIcon;
