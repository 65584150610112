import React, { ReactNode, SyntheticEvent, useRef } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from 'ui/components/atoms/typography';
import { ThemeColors } from 'ui/theme';
import { rem } from 'ui/helpers';
import { useSpatialTarget } from 'utils/spatial-nav';

export type ButtonVariant = 'cta' | 'menu' | 'filter' | 'icon' | 'pill';

export type LabelPosition = 'inside' | 'below' | 'right' | 'left';

export type ButtonSize = 'standard' | 'large';

export type Props = {
  onClick?: (event: SyntheticEvent) => void,
  to?: string | {
    pathname?: string,
    state?: {
      returnRoute: string,
    },
  },
  selected?: boolean,
  focused?: boolean,
  label?: string,
  labelPosition?: LabelPosition,
  icon?: ReactNode,
  variant?: ButtonVariant,
  className?: string,
  iconOnly?: boolean,
  size?: ButtonSize,
  disabled?: boolean,
  dataTest?: string,
  autofocus?: boolean,
  spatialId?: string,
  shouldUpdateIconColor?: boolean,
  onFocus?: () => void,
};

type ButtonColorSchemeOptions = 'default' | 'defaultText' | 'focused' | 'focusedText' |
'selected' | 'selectedText' | 'focusedSelected' | 'focusedSelectedText';

type ButtonColorScheme = { [key in ButtonColorSchemeOptions]?: ThemeColors };

const ctaVariant: ButtonColorScheme = {
  default: 'whiteOpaque',
  defaultText: 'white',
  focused: 'fiitBlue',
  focusedText: 'white',
};

const menuVariant: ButtonColorScheme = {
  defaultText: 'janosGrey',
  focused: 'fiitBlue',
  focusedText: 'white',
  selectedText: 'white',
  focusedSelected: 'fiitBlue',
  focusedSelectedText: 'white',
};

const defaultVariant: ButtonColorScheme = {
  default: 'janosGrey',
  defaultText: 'white',
  focused: 'fiitBlue',
  focusedText: 'white',
  selected: 'white',
  selectedText: 'black',
  focusedSelected: 'white',
  focusedSelectedText: 'fiitBlue',
};

const iconVariant: ButtonColorScheme = {
  defaultText: 'janosGrey',
  focusedText: 'white',
  selectedText: 'white',
  focusedSelectedText: 'white',
};

const pillVariant: ButtonColorScheme = {
  default: 'whiteOpaque',
  defaultText: 'white',
  focused: 'fiitBlue',
  focusedText: 'white',
  selected: 'fiitBlue',
  selectedText: 'white',
  focusedSelected: 'fiitBlue',
  focusedSelectedText: 'white',
};

const variantPresets: Record<ButtonVariant, ButtonColorScheme> = {
  cta: ctaVariant,
  menu: menuVariant,
  filter: defaultVariant,
  icon: iconVariant,
  pill: pillVariant,
};

const colorFromTheme = (selectedColor: ButtonColorSchemeOptions, defaultColor: ButtonColorSchemeOptions) => (
  { selected, colors, theme }: {selected: boolean, colors: ButtonColorScheme, theme: DefaultTheme },
) => {
  const selectedSchemeColor = colors[selectedColor];
  const defaultSchemeColor = colors[defaultColor];
  if (selected) {
    return selectedSchemeColor ? theme.colors[selectedSchemeColor] : 'initial';
  }
  return defaultSchemeColor ? theme.colors[defaultSchemeColor] : 'initial';
};

const getTextColour = (focused: boolean) => (
  focused ? colorFromTheme('focusedSelectedText', 'focusedText')
    : colorFromTheme('selectedText', 'defaultText')
);

const getBackgroundColour = (focused: boolean) => (
  focused ? colorFromTheme('focusedSelected', 'focused')
    : colorFromTheme('selected', 'default')
);

type LabelProps = {
  selected: boolean,
  focused: boolean,
  colors: ButtonColorScheme,
  labelPosition: LabelPosition,
};

type ButtonProps = {
  onClick?: (event: SyntheticEvent) => void,
  onKeyDown?: (event: SyntheticEvent) => void,
  selected: boolean,
  focused?: boolean,
  colors: ButtonColorScheme,
  iconOnly: boolean,
  size: string,
  disabled: boolean,
  'data-test'?: string,
};

const Label = styled(Typography)<LabelProps>`
  display: block;
  white-space: nowrap;
  color: ${({ focused }) => getTextColour(focused)};
  width: 100%;
  line-height: 1;
  ${({ labelPosition }) => (labelPosition === 'inside' ? 'margin-top: 0.1rem;' : '')}
  ${({ labelPosition }) => (labelPosition === 'below' ? `
    margin-top: ${rem(10)};
    text-align: center;
  ` : '')}
  ${({ labelPosition }) => (labelPosition === 'right' ? `margin-left: ${rem(20)};` : '')}
  ${({ labelPosition }) => (labelPosition === 'left' ? `margin-right: ${rem(20)};` : '')}
`;

const IconWrap = styled.div<{
  marginRight?: boolean | false,
  focused: boolean,
  selected: boolean,
  shouldUpdateIconColor?: boolean
}>`
  display: flex; /* why? */
  margin-right: ${({ theme, marginRight }) => (marginRight ? theme.spacing.m : 0)};
  min-height: 1rem;
  min-width: 1rem;
  justify-content: center;
  align-items: center;
  ${({ shouldUpdateIconColor, theme, focused }) => {
    if (shouldUpdateIconColor) {
      return focused ? `color: ${theme.colors.fiitBlue}` : `color: ${theme.colors.black}`;
    }
    return '';
  }}
`;

const FocusableButton = styled.button<ButtonProps>`
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  opacity: 50%;
  border-radius: 2rem;
  fill: currentColor;
  padding: ${({ theme, iconOnly, size }) => {
    if (size === 'large') {
      return `${theme.spacing.l} ${theme.spacing.l}`;
    }

    if (iconOnly) {
      return `${theme.spacing.s} ${theme.spacing.s}`;
    }
    return `${theme.spacing.s} ${theme.spacing.l}`;
  }};
  ${({ focused, selected }) => (focused || selected) && `
    outline: 0;
    opacity: 100%;
  `}

  background-color: ${({ focused = false }) => getBackgroundColour(focused)};

  &:focus {
    background-color: ${getBackgroundColour(true)};
    outline: 0;
    opacity: 100%;
  }
`;

const ButtonWithOutsideLabel = styled.div<{ labelPosition: string }>`
  display: flex;
  flex-direction: ${({ labelPosition }) => (labelPosition === 'below' ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
`;

const Button = ({
  spatialId,
  onClick,
  to,
  selected = false,
  className = '',
  label,
  focused: legacyFocused,
  labelPosition = 'inside',
  icon,
  variant,
  iconOnly = false,
  size = 'standard',
  disabled = false,
  dataTest,
  autofocus = false,
  shouldUpdateIconColor = false,
  onFocus,
}: Props) => {
  const colors = (variant && variantPresets[variant]) || defaultVariant;

  const elRef = useRef(null);
  const { focused } = useSpatialTarget({
    id: spatialId,
    autofocus,
    elRef,
    onFocus: () => {
      if (onFocus) {
        onFocus();
      }
    },
    disabled,
  });

  const labelProps = {
    colors,
    selected,
    focused,
    labelPosition,
    children: label,
  };

  const buttonProps: ButtonProps = {
    onClick,
    // This is a hack - it's not needed in a normal browser, but testcafe events are being weird and clicking twice.
    onKeyDown: (event: SyntheticEvent) => {
      if (onClick && (event as unknown as KeyboardEvent).keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
        onClick(event);
      }
    },
    iconOnly,
    selected,
    disabled,
    colors,
    size,
    focused: focused || legacyFocused,
    'data-test': dataTest,
    // TODO remove focused and only use :focus ?
    ...(to ? { as: Link, to, innerRef: elRef } : { ref: elRef }),
  };

  if (labelPosition !== 'inside') {
    return (
      <ButtonWithOutsideLabel labelPosition={labelPosition} className={className}>
        { label && labelPosition === 'left' && <Label weight="bold" {...labelProps} /> }
        { icon && (
          <FocusableButton
            {...buttonProps}
          >
            <IconWrap
              focused={focused}
              selected={selected}
              shouldUpdateIconColor={shouldUpdateIconColor}
            >
              { icon }
            </IconWrap>
          </FocusableButton>
        )}
        { label && ['right', 'below'].includes(labelPosition) && <Label weight="bold" {...labelProps} /> }
      </ButtonWithOutsideLabel>
    );
  }

  return (
    <FocusableButton
      className={className}
      {...buttonProps}
    >
      { icon && (
        <IconWrap
          focused={focused}
          selected={selected}
          marginRight={!!label}
          shouldUpdateIconColor={shouldUpdateIconColor}
        >
          { icon }
        </IconWrap>
      )}
      { label && <Label weight="bold" {...labelProps} /> }
    </FocusableButton>
  );
};

export default Button;
