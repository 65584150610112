
import { useQuery } from '@apollo/react-hooks';
import { useAppState, useDispatch } from 'state';

import { setVirtualStudio, VirtualStudioPermissions } from 'actions/virtual-studio';

import { VirtualStudioByUrlQuery, VirtualStudioByUrlQueryVariables } from 'app/in-studio/types/graphql';
import { VirtualStudioByUrl as VIRTUAL_STUDIO_QUERY } from 'app/in-studio/graphql/queries/virtual-studio.gql';

type VirtualStudio = {
  name: string | null,
  permissions: VirtualStudioPermissions,
  isChecked: boolean,
  isSet: boolean,
};

const useVirtualStudio = (): VirtualStudio => {
  const virtualStudio = useAppState((state) => state.virtualStudio);
  const dispatch = useDispatch();

  const { data, loading } =
    useQuery<VirtualStudioByUrlQuery, VirtualStudioByUrlQueryVariables>(VIRTUAL_STUDIO_QUERY, {
      variables: {
        url: window.location.origin,
      },
      skip: !!virtualStudio.name,
    });

  const { virtualStudioByUrl } = data || {};

  if (!virtualStudio.name && !loading) {
    if (virtualStudioByUrl) {
      // we've checked and got a response, and the partner exists
      dispatch(setVirtualStudio(virtualStudioByUrl));
      return { ...virtualStudioByUrl, isChecked: true, isSet: true };
    }
    // we've checked and got a response, but it doesnt exist.
    return { ...virtualStudio, isChecked: true };
  }

  // if no data to be set
  return virtualStudio;
};

export default useVirtualStudio;
