import React, { Dispatch, ReactNode, SetStateAction, useRef } from 'react';

import styled from 'styled-components';
import { useSpatialTarget } from 'utils/spatial-nav';

export type CommonProps = {
  id: string,
  onFocus: Dispatch<SetStateAction<string>>,
  onClick?: () => void,
  className?: string,
  isBreakSection?: boolean,
};

type BreakdownSectionRowProps = CommonProps & {
  children: ReactNode[],
};

const WorkoutSectionContainer = styled.button<{focused: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;

  &:focus {
    background-color: ${({ theme }) => theme.colors.fiitBlue};
    transition: all 0.2s;
  }
`;

const BreakdownSectionRow = ({
  id,
  isBreakSection = false,
  onFocus,
  onClick,
  children,
  className = '',
}: BreakdownSectionRowProps) => {
  const elRef = useRef<HTMLButtonElement | null>(null);

  const { focused, setFocus } = useSpatialTarget({
    elRef,
    disabled: isBreakSection,
    onFocus: () => onFocus(id),
  });

  return (
    <WorkoutSectionContainer
      ref={elRef}
      className={className}
      focused={focused}
      onClick={() => {
        if (onClick) {
          setFocus();
          onClick();
        }
      }}
    >
      {children}
    </WorkoutSectionContainer>
  );
};

export default BreakdownSectionRow;
