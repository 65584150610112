/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentClass } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RoutePermissions } from 'app/in-studio/utils/route-permissions';
import useAllRouteConditions from 'app/in-studio/hooks/use-all-route-conditions';

export type Props = {
  component: ((args: any) => (JSX.Element) | null) | ComponentClass<any>;
  exact?: boolean;
  path?: string[];
  requiredPermissions?: RoutePermissions[],
  title?: string,
};

const ProtectedRoute = ({ component: Component, ...rest }: Props) => {
  const { requiredPermissions = [] } = rest;
  const {
    loggedIn,
    platformChecked,
    studioSet,
    permissionsFulfilled,
  } = useAllRouteConditions(requiredPermissions);

  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (platformChecked && !studioSet) {
            return <Redirect to={{ pathname: '/studio-not-found' }} />;
          }

          if (platformChecked && studioSet && !permissionsFulfilled) {
            return <Redirect to={{ pathname: '/' }} />;
          }

          if (!loggedIn) {
            return <Redirect to={{ pathname: '/login', state: { returnRoute: props.location } }} />;
          }

          return <Component {...props} />;
        }
      }
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }: Props) => {
  const { requiredPermissions = [] } = rest;
  const {
    loggedIn,
    platformChecked,
    studioSet,
  } = useAllRouteConditions(requiredPermissions);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (window.location.pathname === '/studio-not-found') {
          return <Component {...props} />;
        }

        if (loggedIn) {
          const path = props.location?.state?.returnRoute || { pathname: '/' };
          const fromSignUpRoute = props.location.pathname.includes('signup');

          return fromSignUpRoute
            ? <Redirect to={{ pathname: '/premium', search: `?fromSignUpRoute=${fromSignUpRoute}` }} />
            : <Redirect to={path} />;
        }

        if (platformChecked && !studioSet) {
          return <Redirect to={{ pathname: '/studio-not-found' }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export {
  ProtectedRoute,
  LoginRoute,
};
