import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSpatialTarget } from 'utils/spatial-nav';

import Typography from 'ui/components/atoms/typography';

const DescriptionText = styled(Typography)`
  white-space: pre-line;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

const DescriptionContainerInner = styled.div<{ focused?: boolean }>`
  border-left: 0.1rem solid ${({ theme, focused }) => (focused ? theme.colors.fiitBlue : theme.colors.beckersKnop)};
`;

const DescriptionContent = styled.button<{ expanded: boolean, disableTransition: boolean }>`
  overflow: hidden;
  padding-left: 0.5rem;
  border-radius: 2px;
  text-align: left;
  ${({ disableTransition }) => (disableTransition ? '' : 'transition: max-height 0.5s ease')};
  ${({ expanded }) => (expanded ? '' : 'mask-image: linear-gradient(to bottom, black 50%, transparent 100%)')};
`;

const DescriptionTitle = styled(Typography)`
  margin-bottom: 1rem;
`;

type DescriptionProps = {
  description: string,
  title?: string,
  className?: string,
  onFocus: (target: HTMLElement | null) => void,
  onExpand?: (target: HTMLElement | null, reset: boolean) => void,
  disableTransition?: boolean,
};

const ExpandableDescription = ({
  className = '',
  description,
  title,
  onFocus,
  onExpand,
  disableTransition = false,
} : DescriptionProps) => {
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const elRef = useRef<HTMLButtonElement | null>(null);
  const { focused, setFocus } = useSpatialTarget({
    elRef,
    onFocus,
  });

  useEffect(() => {
    if (!focused && descriptionExpanded) {
      setDescriptionExpanded(false);
    }
  }, [focused, descriptionExpanded]);

  const onClick = () => {
    setFocus();

    if (onExpand && elRef.current) {
      onExpand(elRef.current, descriptionExpanded);
    }

    setDescriptionExpanded((prev) => !prev);
  };

  return (
    <DescriptionContainer className={className}>
      <DescriptionTitle variant="double-pica">{title}</DescriptionTitle>
      <DescriptionContainerInner focused={focused}>
        <DescriptionContent
          ref={elRef}
          onClick={onClick}
          style={{ maxHeight: descriptionExpanded ? elRef?.current?.scrollHeight : '6rem' }}
          expanded={descriptionExpanded}
          disableTransition={disableTransition}
        >
          <DescriptionText>{description}</DescriptionText>
        </DescriptionContent>
      </DescriptionContainerInner>
    </DescriptionContainer>
  );
};

export default ExpandableDescription;
